<template>
  <div>
    <v-card class="flex justify-center" flat tile>
      <div
        class="primary d-flex flex-column justify-space-between align-center pt-2"
      >
        <!-- <span class="font-logo white--text">
          <span class="red--text">X</span>Software-Mix</span
        > -->
        <v-img width="200" src="../../src/assets/xsmixbranco.png"></v-img>
      </div>

      <v-toolbar height="0" flat dense dark color="primary">
        <template v-slot:extension>
          <v-btn
            fab
            color="blue-grey"
            bottom
            left
            absolute
            dark
            :loading="boolLoading"
            @click="dialog = !dialog"
          >
            <v-icon light>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <v-list two-line subheader>
        <v-subheader inset> </v-subheader>

        <v-list-item
          v-for="(empresa, n) in empresas"
          :key="empresa.nome"
          link
          @click="login(n)"
        >
          <v-list-item-avatar>
            <v-icon class="grey lighten-1 white--text">
              mdi-office-building-marker-outline
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ empresa.nome }}</v-list-item-title>
            <v-list-item-subtitle>{{ empresa.cnpj }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="editEmpresa(n)">
              <v-icon color="grey lighten-1"> mdi-cog </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-text></v-card-text>
          <v-card-text>
            <v-text-field v-model="tpai.nome" label="Empresa"></v-text-field>
            <v-text-field v-model="tpai.cnpj" label="CNPJ"> </v-text-field>
            <v-text-field v-model="tpai.xnoip" label="XNoIP"></v-text-field>
            <!-- <v-text-field v-model="tpai.porta" label="Porta"></v-text-field> -->
            <v-text-field
              v-model="tpai.token"
              label="Token"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field v-model="tpai.usuario" label="Usuário"></v-text-field>
            <v-text-field
              v-model="tpai.senha"
              label="Senha"
              :type="show2 ? 'text' : 'password'"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="indexEdicao !== null"
              color="error"
              @click="removeEmpresa(indexEdicao)"
            >
              Excluir
            </v-btn>
            <v-btn color="warning" @click="cancelEmpresa"> Cancelar </v-btn>
            <v-btn color="primary" @click="saveEmpresa"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </div>
</template>

<script>
import api from "../services/api.js";
import xnoip from "../services/xnoip.js";
import router from "./../router";

export default {
  name: "Home",
  data() {
    return {
      show1: false,
      show2: false,
      dialog: false,
      indexEdicao: null,
      snackbar: false,
      snackbartext: "",
      boolLoading: false,
      empresas: [],
      tlogin: {
        usuario: "",
        senha: "",
        cnpj: "",
        token: "",
      },
      tpai: {
        nome: null,
        cnpj: null,
        xnoip: null,
        //porta: null,
        token: null,
        usuario: null,
        senha: null,
      },
    };
  },
  mounted() {
    this.limpaDataset();
    this.loadEmpresas();
  },
  methods: {
    limpaDataset() {
      this.tpai.nome = null;
      this.tpai.cnpj = null;
      this.tpai.xnoip = null;
      //this.tpai.porta = null;
      this.tpai.token = null;
      this.tpai.usuario = null;
      this.tpai.senha = null;
    },
    saveEmpresa() {
      if (!this.tpai) {
        return;
      }

      this.tpai.nome = this.tpai.nome.toUpperCase();
      this.tpai.cnpj = this.tpai.cnpj.toUpperCase();
      this.tpai.xnoip = this.tpai.xnoip.toUpperCase();
      //this.tpai.porta = this.tpai.porta.toUpperCase();
      this.tpai.usuario = this.tpai.usuario.toUpperCase();
      this.tpai.senha = this.tpai.senha.toUpperCase();

      if (this.indexEdicao === null) {
        this.empresas.push(this.tpai);
        this.saveLocalStorage();
      } else {
        this.empresas[this.indexEdicao] = this.tpai;
        this.indexEdicao = null;
        this.saveLocalStorage();
      }
      this.limpaDataset();
      this.dialog = false;
      this.show1 = false;
      this.show2 = false;
      this.loadEmpresas();
    },
    cancelEmpresa() {
      this.indexEdicao = null;
      this.limpaDataset();
      this.dialog = false;
      this.show1 = false;
      this.show2 = false;
      this.loadEmpresas();
    },
    removeEmpresa(x) {
      this.empresas.splice(x, 1);
      this.dialog = false;
      this.show1 = false;
      this.show2 = false;
      this.saveLocalStorage();
    },
    saveLocalStorage() {
      const parsed = JSON.stringify(this.empresas);
      localStorage.setItem("empresas", parsed);
    },
    editEmpresa(x) {
      this.indexEdicao = x;
      this.tpai = this.empresas[x];
      this.show1 = false;
      this.show2 = false;
      this.dialog = true;
    },
    loadEmpresas() {
      if (localStorage.getItem("empresas")) {
        try {
          this.empresas = JSON.parse(localStorage.getItem("empresas"));
        } catch (e) {
          this.snackbartext = "Erro ao acessar localstorage";
          this.snackbar = true;
        }
      }
    },
    login(x) {
      if (
        this.empresas[x].usuario.length > 0 &&
        this.empresas[x].senha.length > 0 &&
        this.empresas[x].xnoip.length > 0
      ) {
        this.boolLoading = true;

        //configurando headers para não usar cache de disco
        xnoip.defaults.headers = {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        };
        api.defaults.headers = {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        };

        //pegando ip no XNoIP
        xnoip
          .get("/xnoip/" + this.empresas[x].xnoip)
          .then((response) => {
            //verifica se está dentro da empresa
            if (response.data[0].NUMERO_IP === response.data[0].REMOTEADDR) {
              this.$store.state.baseURL = "http://" + response.data[0].IP_LOCAL;
              this.$store.state.baseIP = response.data[0].IP_LOCAL;
              this.$store.state.basePORT = response.data[0].PORTA_REST;
            } else if (response.data[0].PORTA_REST === "") {
              //se nao tem porta rest esta usando https
              this.$store.state.baseURL =
                "https://" + response.data[0].NUMERO_IP;
              this.$store.state.baseIP = response.data[0].NUMERO_IP;
              this.$store.state.basePORT = response.data[0].PORTA_REST;
            } else {
              //conexao normal pela porta rest
              this.$store.state.baseURL =
                "http://" + response.data[0].NUMERO_IP;
              this.$store.state.baseIP = response.data[0].NUMERO_IP;
              this.$store.state.basePORT = response.data[0].PORTA_REST;
            }
            //setando nova baseURL com ip do XNoIP
            if (response.data[0].PORTA_REST === "") {
              api.defaults.baseURL = this.$store.state.baseURL;
            } else {
              api.defaults.baseURL =
                this.$store.state.baseURL + ":" + response.data[0].PORTA_REST;
            }

            // this.tlogin.usuario = this.empresas[x].usuario;
            // this.tlogin.senha = this.empresas[x].senha;
            // this.tlogin.cnpj = this.empresas[x].cnpj;
            // this.tlogin.token = this.empresas[x].token;
            // api
            // .post("/v1/login2", JSON.stringify(this.tlogin))

            api
              .get(
                "/v1/login?usuario=" +
                  this.empresas[x].usuario +
                  "&senha=" +
                  this.empresas[x].senha +
                  "&cnpj=" +
                  this.empresas[x].cnpj +
                  "&token=" +
                  this.empresas[x].token
              )
              .then((response) => {
                this.boolLoading = false;
                if (response.data.ACESSO == "OK") {
                  this.$store.state.boolMostraAppBar = true;
                  this.$store.state.codusuario = response.data.N_USU;
                  this.$store.state.intN_PER = response.data.N_PER;
                  this.$store.state.strDESCRICAO_PER =
                    response.data.DESCRICAO_PER;
                  this.$store.state.usuario = response.data.LOGIN;
                  this.$store.state.codfuncionario = response.data.N_PES;
                  this.$store.state.vempresa = response.data.N_EMP;
                  this.$store.state.vempresanome = response.data.NOME_EMP;
                  this.$store.state.vtitulojanela = response.data.NOME_EMP;
                  this.$store.state.tipo_empresa = response.data.TIPO_EMPRESA;
                  this.$store.state.tipo2_empresa = response.data.TIPO2_EMPRESA;

                  //carrega dados da empresa
                  this.carregaDadosEmpresa();

                  router.push({ path: "/principal" });
                } else {
                  this.snackbartext = response.data.ACESSO;
                  this.snackbar = true;
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  this.boolLoading = false;
                  this.snackbartext = error.response.data;
                  this.snackbar = true;
                } else {
                  this.boolLoading = false;
                  this.snackbartext = "Erro na conexão";
                  this.snackbar = true;
                }
              });
          })
          .catch(() => {
            this.boolLoading = false;
            this.snackbartext = "Erro ao conectar no XNoIP";
            this.snackbar = true;
          });
      } else {
        this.snackbartext = "Dados incompletos";
        this.snackbar = true;
      }
    },
    carregaDadosEmpresa() {
      let sql =
        "select e.n_emp, e.razao_emp, e.nome_emp, e.cnpj_emp, e.ie_emp, e.end_emp, e.bairro_emp, e.cidade_emp, " +
        "e.uf_emp, e.tel_emp, e.endnumero_emp, e.tipo_empresa, e.tipo2_empresa, e.grupoecon_emp, e.mult_cadprod_emp " +
        "from empresa e " +
        "where e.n_emp = " +
        this.$store.state.vempresa;
      sql = encodeURIComponent(sql);
      this.$store.state.qEmpresa = [];
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.$store.state.qEmpresa = response.data[0];
        //this.$store.state.qEmpresa = this.$store.state.qEmpresa[0];
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.font-logo-footer {
  font-family: "Righteous", cursive;
  font-size: 20px;
}
.font-logo-mobile {
  font-family: "Righteous", cursive;
  font-size: 30px;
}
.font-logo {
  font-family: "Righteous", cursive;
  font-size: 40px;
}
</style>