<template>
  <v-container fluid class="d-flex flex-column">
    <!-- DIV da pesquisa -->
    <div v-if="strStatusRegistro === ''">
      <div class="pa-2">
        <v-toolbar max-width="500px" class="blue-grey lighten-5">
          <v-text-field
            v-model="edPesquisa"
            hide-details
            single-line
            label="Cliente/Pedido"
          ></v-text-field>
          <v-btn :loading="isLoading" icon @click="btnPesquisarClick">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <div class="pa-2">
        <v-data-table
          v-if="bolMostraGrid"
          :headers="headers"
          :items="qGrid"
          sort-by="nome"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
          <template v-slot:[`item.botoes`]="{ item }">
            <v-btn
              class="mr-1 my-1"
              outlined
              color="primary"
              dark
              @click="btnAlterarClick(item)"
            >
              Editar
            </v-btn>
            <!-- <v-icon @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- DIV dos campos do cadastro -->
    <div v-if="strStatusRegistro !== ''">
      <v-card class="pa-2">
        <v-form ref="form" lazy-validation>
          <v-col v-if="strStatusRegistro === 'EDIT'">
            <v-text-field
              v-model="tpai.N_PED"
              label="Pedido"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col v-if="strStatusRegistro === 'EDIT'">
            <v-text-field
              v-model="tpai.DATA_CAD"
              label="Data"
              type="date"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.RAZAO_CLI"
              label="Cliente"
              required
              disabled
            >
              <template v-slot:append-outer>
                <v-btn icon @click="btnPesquisarClienteClick">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col>
            <v-text-field
              v-model="tpai.CPFCNPJ_CLI"
              label="CPF/CNPJ"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CONTATO_CLI"
              label="Contato"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.TELEFONE_CLI"
              label="Telefone"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CELULAR_CLI"
              label="Celular"
            ></v-text-field>
          </v-col> 
          <v-card dark color="primary">
            <div class="pa-2 text-center text-h6">Endereço de entrega</div>
          </v-card>
          <v-col>
            <v-text-field
              v-model="tpai.CEP_CLI"
              label="CEP"
              required
              append-outer-icon="mdi-magnify"
              @click:append-outer="btnConsultaViaCEPClick"
              :loading="isLoadingCEP"
            ></v-text-field>
          </v-col>
           -->
          <v-col>
            <v-select
              v-model="tpai.ENTREGAR_PED"
              :items="['SIM', 'NÃO']"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="Entregar"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.ENTREGARDATA_PED"
              label="Data de entrega"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="tpai.ENTREGARPERIODO_PED"
              :items="['MANHÃ', 'TARDE']"
              label="Período de entrega"
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.END_ENT"
              label="Endereço de entrega diferente"
              required
              clearable
              append-outer-icon="mdi-magnify"
              @click:append-outer="btnPesquisarEnderecoClick"
              @click:clear="onClearEnderecoClick"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="btnCadastrarEnderecoClick">
              Cadastrar novo endereço
            </v-btn>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-select
              v-model="tpai.TIPOEND_ENT"
              :items="this.$store.state.itemsTipoEnd"
              label="Tipo de endereço"
            ></v-select>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-text-field
              v-model="tpai.END_ENT"
              label="Logradouro"
            ></v-text-field>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-text-field
              v-model="tpai.NUMERO_ENT"
              label="Número"
            ></v-text-field>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-text-field
              v-model="tpai.COMPLEMENTO_ENT"
              label="Complemento"
            ></v-text-field>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-text-field
              v-model="tpai.BAIRRO_ENT"
              label="Bairro"
            ></v-text-field>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-text-field
              v-model="tpai.CIDADE_ENT"
              label="Cidade"
            ></v-text-field>
          </v-col>
          <v-col v-if="bolNovoEndereco">
            <v-select
              v-model="tpai.UF_ENT"
              :items="this.$store.state.itemsUF"
              label="UF"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="tpai.N_TIP"
              :items="qTipoCobranca"
              item-text="DESCRICAO"
              item-value="N_TIP"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="Forma de pagto"
              required
              :disabled="bolTravaCampoTipoCobranca"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.PRAZOPGTO_PED"
              label="Prazo de pagto"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-textarea
              v-model="tpai.OBS_PED"
              outlined
              label="Observações"
              height="300px"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-row class="ma-1" justify="center">
              <v-btn color="blue-grey" dark @click="btnMostrarItensClick">
                <v-icon dark> mdi-list-box-outline </v-icon>
                Itens do pedido
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card>
    </div>
    <!-- Footer da inclusao -->
    <v-footer
      v-if="strStatusRegistro === ''"
      app
      color="primary"
      height="55"
      elevation="20"
    >
      <v-row class="justify-end">
        <v-btn
          v-if="this.$store.state.qPerfil.INCLUIR === 'S'"
          class="ma-2"
          fab
          dark
          small
          color="blue"
          @click="btnIncluirClick"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
    <!-- Footer da edição -->
    <v-footer v-if="strStatusRegistro !== ''" app color="primary">
      <v-btn
        v-if="
          strStatusRegistro === 'INSERT' ||
          (strStatusRegistro === 'EDIT' &&
            this.$store.state.qPerfil.ALTERAR === 'S')
        "
        color="success"
        class="mr-4"
        @click="btnSalvarClick"
      >
        Salvar
      </v-btn>
      <v-btn color="warning" class="mr-4" @click="btnCancelarClick">
        Cancelar
      </v-btn>
      <v-bottom-sheet
        v-if="
          strStatusRegistro === 'EDIT' &&
          this.$store.state.qPerfil.EXCLUIR === 'S'
        "
        v-model="bolPerguntaExcluir"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" v-bind="attrs" v-on="on"> Excluir </v-btn>
        </template>
        <v-sheet class="text-center" height="200px">
          <div class="py-3">Deseja realmente excluir este registro?</div>
          <v-card class="d-flex justify-space-around mb-6" flat tile>
            <v-btn color="error" @click="btnExcluirClick"> Sim </v-btn>
            <v-btn
              color="success"
              @click="bolPerguntaExcluir = !bolPerguntaExcluir"
            >
              Não
            </v-btn>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-footer>
    <!-- Dialog da pesquisa de clientes -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogPesqCliente"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="success">
          <v-btn icon @click.stop="bolDialogPesqCliente = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-text-field
            v-model="edPesquisaCliente"
            hide-details
            single-line
            label="Pesquisa de clientes"
          ></v-text-field>
          <v-btn :loading="isLoading" icon @click="btnConsultarClienteClick">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGridCliente"
            :headers="headerscliente"
            :items="qGridClientes"
            sort-by="nome"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                color="success"
                outlined
                @click="btnSelecionarClienteClick(item)"
                >SELECIONAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog da pesquisa de clientes -->
    <!-- Dialog dos itens -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogItens"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="blue-grey">
          <v-btn icon @click.stop="bolDialogItens = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>ITENS DO PEDIDO</v-toolbar-title>
        </v-toolbar>
        <div>
          <v-col>
            <v-row class="ma-1" justify="center">
              <v-btn color="blue-grey" dark @click="btnAdicionarProdutoClick">
                Adicionar produto
              </v-btn>
            </v-row>
          </v-col>
        </div>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGridItens"
            :headers="headersitens"
            :items="qGridFilho"
            sort-by="nome"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ index }">
              <v-btn
                class="ma-1"
                color="error"
                outlined
                @click="btnExcluirProdutoClick(index)"
                >EXCLUIR</v-btn
              >
              <v-btn
                class="ma-1"
                color="blue-grey"
                outlined
                @click="btnEditarProdutoClick(index)"
                >EDITAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog dos itens -->
    <!-- Dialog do produto -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogProduto"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="blue-grey darken-4">
          <v-btn icon @click.stop="bolDialogProduto = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>ITEM DO PEDIDO</v-toolbar-title>
        </v-toolbar>
        <v-card class="pa-2">
          <v-form ref="form" lazy-validation>
            <v-col>
              <v-text-field
                v-model="strNomeProdutoSelecionado"
                label="Produto"
                required
                disabled
              >
                <template v-slot:append-outer>
                  <v-btn icon @click="btnPesquisarProdutoClick">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template></v-text-field
              >
            </v-col>
            <v-col>
              <v-text-field
                v-model="tfilho.QTDE_ITEM"
                label="Qtde"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="tfilho.VALORUNITARIO_ITEM"
                label="Valor unitário"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-row class="ma-1" justify="center">
                <v-btn
                  color="blue-grey darken-4"
                  dark
                  @click="btnSalvarProdutoClick"
                >
                  Salvar
                </v-btn>
              </v-row>
            </v-col>
          </v-form>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog do produto -->
    <!-- Dialog da pesquisa de produtos -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogPesqProduto"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="red">
          <v-btn icon @click.stop="bolDialogPesqProduto = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-text-field
            v-model="edPesquisaProduto"
            hide-details
            single-line
            label="Pesquisa de produtos"
          ></v-text-field>
          <v-btn :loading="isLoading" icon @click="btnConsultarProdutoClick">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGridProduto"
            :headers="headersproduto"
            :items="qGridProdutos"
            sort-by="NOME_PRO"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                color="success"
                outlined
                @click="btnSelecionarProdutoClick(item)"
                >SELECIONAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog da pesquisa de produtos -->
    <!-- Dialog da pesquisa de endereços -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogPesqEndereco"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="purple">
          <v-btn icon @click.stop="bolDialogPesqEndereco = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-2">
          <v-data-table
            :headers="headersendereco"
            :items="qGridEnderecos"
            sort-by="N_END"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                color="success"
                outlined
                @click="btnSelecionarEnderecoClick(item)"
                >SELECIONAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog da pesquisa de endereços -->
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";
import viacep from "../services/viacep.js";
import { applyCurrencyMask } from "../utils/utils.js";

export default {
  name: "Pedidos",

  data() {
    return {
      rawValue: "", // Armazena o valor real sem máscara
      formattedValue: "", // Armazena o valor com máscara

      //veriaveis
      edPesquisa: "",
      strStatusRegistro: "",
      strStatusItem: "",
      snackbar: false,
      snackbartext: "",
      isLoading: false,
      isLoadingCEP: false,
      bolMostraGrid: false,
      bolPerguntaExcluir: false,
      bolPerguntaExcluirItem: false,
      bolDialogPesqCliente: false,
      bolMostraGridCliente: false,
      bolDialogItens: false,
      bolMostraGridItens: false,
      bolDialogProduto: false,
      bolDialogPesqEndereco: false,
      bolPDF: false,
      bolTravaCampoTipoCobranca: false,
      edPesquisaCliente: "",
      dataHoje: "",
      intIndexEditado: 0,
      bolDialogPesqProduto: false,
      edPesquisaProduto: "",
      bolMostraGridProduto: false,
      strNomeProdutoSelecionado: "",
      qGridProdutos: [],
      headersproduto: [
        {
          text: "Código",
          align: "start",
          value: "N_PRO_MARCA",
        },
        { text: "Nome", value: "NOME_PRO" },
        { text: "Un", value: "UN_PRO" },
        { text: "Valor", value: "VALOR_VENDA" },
        { text: "", value: "acoes", sortable: false },
      ],

      //datasets
      tpai: {},
      tfilho: [],
      qGridFilho: {},
      qConsulta: [],
      qGrid: [],
      qTipoCobranca: [],
      qGridClientes: [],
      qProdutos: [],
      qGridEnderecos: [],
      //headers das grids
      headers: [
        {
          text: "Pedido",
          align: "start",
          value: "N_PED",
        },
        { text: "Data", value: "DATA_CAD" },
        { text: "Vendedor", value: "NOME_VENDEDOR" },
        { text: "Status", value: "STATUS_PED" },
        { text: "Valor", value: "VALOR_PED" },
        { text: "Cliente", value: "RAZAO_PES" },
        { text: "Endereço", value: "ENDCOMPLETO_PES" },
        { text: "Cidade", value: "CIDADE_PES" },
        { text: "", value: "botoes", sortable: false },
      ],
      headerscliente: [
        {
          text: "Razão Social",
          align: "start",
          value: "RAZAO_PES",
        },
        { text: "Nome Fantasia", value: "NOME_PES" },
        { text: "CPF/CNPJ", value: "CPFCNPJ_PES" },
        { text: "Endereço", value: "ENDCOMPLETO_PES" },
        { text: "Cidade/UF", value: "CIDADE_PES" },
        { text: "", value: "acoes", sortable: false },
      ],
      headersendereco: [
        {
          text: "Descrição",
          align: "start",
          value: "DESCRICAO_END",
        },
        { text: "Endereço", value: "ENDCOMPLETO_END" },
        // { text: "Número", value: "NUMERO_END" },
        { text: "Bairro", value: "BAIRRO_END" },
        { text: "Cidade/UF", value: "CIDADE_END" },
        { text: "", value: "acoes", sortable: false },
      ],
      headersitens: [
        {
          text: "Produto",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Un", value: "UN_PRO" },
        { text: "Qtde", value: "QTDE_ITEM" },
        { text: "Valor unit.", value: "VALORUNITARIO_ITEM" },
        { text: "Valor total", value: "VALORTOTAL_ITEM" },
        { text: "", value: "acoes", sortable: false },
      ],
      bolFiltraVendedor: false,
      bolNovoEndereco: false,
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "PEDIDOS";
    this.getDataHoje();
    this.carregaTipoCobranca();
    this.filtravendedorlogado();
  },
  watch: {
    qGridFilho: function (val) {
      if (val.length == 0) {
        this.bolTravaCampoTipoCobranca = false;
      } else {
        this.bolTravaCampoTipoCobranca = true;
      }
    },
  },
  methods: {
    btnCadastrarEnderecoClick() {
      this.bolNovoEndereco = !this.bolNovoEndereco;
      this.tpai.TIPOEND_ENT = null;
      this.tpai.END_ENT = null;
      this.tpai.NUMERO_ENT = null;
      this.tpai.COMPLEMENTO_ENT = null;
      this.tpai.BAIRRO_ENT = null;
      this.tpai.CIDADE_ENT = null;
      this.tpai.UF_ENT = null;
      this.tpai.N_END = null;
    },
    onClearEnderecoClick() {
      this.tpai.N_END = null;
      this.tpai.TIPOEND_ENT = null;
      this.tpai.END_ENT = null;
      this.tpai.NUMERO_ENT = null;
      this.tpai.COMPLEMENTO_ENT = null;
      this.tpai.BAIRRO_ENT = null;
      this.tpai.CIDADE_ENT = null;
      this.tpai.UF_ENT = null;
    },
    filtravendedorlogado() {
      this.bolFiltraVendedor = true;
    },
    btnExcluirProdutoClick(index) {
      this.qGridFilho.splice(index, 1);
    },
    btnEditarProdutoClick(index) {
      this.strStatusItem = "EDIT";
      this.intIndexEditado = index;
      this.tfilho = {};
      this.tfilho = this.qGridFilho[index];
      this.strNomeProdutoSelecionado = this.qGridFilho[index].NOME_PRO;
      this.bolDialogProduto = true;
    },
    btnSalvarProdutoClick() {
      this.tfilho.QTDE_ITEM = applyCurrencyMask(this.tfilho.QTDE_ITEM);
      this.tfilho.VALORUNITARIO_ITEM = applyCurrencyMask(
        this.tfilho.VALORUNITARIO_ITEM
      );
      if (
        this.tfilho.QTDE_ITEM == "" ||
        this.tfilho.QTDE_ITEM == "0" ||
        this.tfilho.QTDE_ITEM == null ||
        this.tfilho.QTDE_ITEM == 0
      ) {
        this.snackbartext = "Por favor infome a quantidade";
        this.snackbar = true;
      } else if (
        this.tfilho.VALORUNITARIO_ITEM == "" ||
        this.tfilho.VALORUNITARIO_ITEM == "0" ||
        this.tfilho.VALORUNITARIO_ITEM == null ||
        this.tfilho.VALORUNITARIO_ITEM == 0
      ) {
        this.snackbartext = "Por favor infome o valor unitário";
        this.snackbar = true;
      } else {
        this.tfilho.NOME_PRO = this.strNomeProdutoSelecionado;

        this.tfilho.VALORTOTAL_ITEM =
          this.tfilho.VALORUNITARIO_ITEM * this.tfilho.QTDE_ITEM;
        this.tfilho.VALORTOTAL_ITEM = parseFloat(
          this.tfilho.VALORTOTAL_ITEM
        ).toFixed(2);

        if (this.strStatusItem == "INSERT") {
          this.qGridFilho.push({ ...this.tfilho });
        } else {
          this.$set(this.qGridFilho, this.intIndexEditado, this.tfilho);
        }
        this.bolDialogProduto = false;
        this.bolMostraGridItens = true;
      }
    },
    btnConsultarProdutoClick() {
      if (this.edPesquisaProduto.length >= 3) {
        let sql =
          "select p.n_pro, m.n_pro_marca, p.nome_pro, p.un_pro, " +
          "coalesce(cast((select first(1) s.valor_promo " +
          "from promocoes s " +
          "where s.n_pro_marca = m.n_pro_marca " +
          "and s.dataini_promo <= current_date " +
          "and s.datafim_promo >= current_date " +
          "and s.n_emp = " +
          this.$store.state.vempresa +
          " " +
          "order by s.n_promo desc " +
          ") as float),cast(m.valor_venda as float)) valor_venda " +
          "from produtos p ,produtos_marcas m, empresa e " +
          "where p.n_pro = m.n_pro " +
          "and p.n_emp = e.n_emp " +
          "and p.tipo_pro = 'VENDA' " +
          "and p.tipo_material is null ";

        if (
          this.$store.state.qEmpresa.MULT_CADPROD_EMP == "INDIVIDUAL" ||
          this.$store.state.qEmpresa.MULT_CADPROD_EMP == ""
        ) {
          sql = sql + "and p.n_emp = " + this.$store.state.vempresa + " ";
        } else {
          if (this.$store.state.qEmpresa.GRUPOECON_EMP != "") {
            sql =
              sql +
              "and e.grupoecon_emp = '" +
              this.$store.state.qEmpresa.GRUPOECON_EMP +
              "' ";
          }
        }

        sql =
          sql +
          "and p.nome_pro like '%" +
          this.edPesquisaProduto +
          "%' " +
          "and p.status_pro = 'ATIVO' " +
          "and m.status_marca = 'ATIVO' " +
          "order by p.nome_pro";
        sql = encodeURIComponent(sql);
        this.qGridProdutos = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.bolMostraGridProduto = true;
          this.qGridProdutos = response.data;
        });
      }
    },
    btnAdicionarProdutoClick() {
      this.strStatusItem = "INSERT";
      this.localizaregtfilho();
      this.tfilho.N_ITEM = "0"; //indica insert
      this.tfilho.N_PED = this.tpai.N_PED;
      this.strNomeProdutoSelecionado = "";
      this.bolDialogProduto = true;
    },
    btnMostrarItensClick() {
      if (this.tpai.N_TIP == null) {
        this.snackbartext = "Selecione a forma de pagto";
        this.snackbar = true;
      } else {
        this.bolMostraGridItens = false;
        this.bolDialogItens = true;
        this.btnConsultarItensClick();
      }
    },
    btnConsultarItensClick() {
      if (this.qGridFilho.length === 0) {
        if (this.tpai.N_PED !== "0") {
          //se a qGridFilho estiver vazio pesquisa na api, senão mantem os itens
          this.isLoading = true;
          let sql =
            "select i.n_item, i.n_pro_marca, i.qtde_item, p.nome_pro, p.un_pro, " +
            "cast(i.valorunitario_item as float) valorunitario_item, " +
            "cast(i.valortotal_item as float) valortotal_item " +
            "from pedidoitens i, produtos_marcas m, produtos p " +
            "where i.n_ped = " +
            this.tpai.N_PED +
            " " +
            "and i.n_pro_marca = m.n_pro_marca " +
            "and m.n_pro = p.n_pro " +
            "order by p.nome_pro, p.n_pro";
          sql = encodeURIComponent(sql);
          this.qGridFilho = [];
          api.get("/v1/consulta?sql=" + sql).then((response) => {
            this.qGridFilho = response.data;
            this.bolMostraGridItens = true;
            this.isLoading = false;
          });
        } else {
          this.qGridFilho = [];
          this.bolMostraGridItens = false;
        }
      } else {
        this.bolMostraGridItens = true;
      }
    },
    btnPesquisarClienteClick() {
      this.edPesquisaCliente = "";
      this.qGridClientes = [];
      this.bolMostraGridCliente = false;
      this.bolDialogPesqCliente = true;
    },
    btnPesquisarEnderecoClick() {
      if (this.tpai.N_PES_CLIENTE == null) {
        this.snackbartext = "Cliente não selecionado";
        this.snackbar = true;
      } else {
        this.qGridEnderecos = [];
        this.btnConsultarEnderecoClick();
        this.bolDialogPesqEndereco = true;
      }
    },
    btnConsultarEnderecoClick() {
      this.isLoading = true;
      let sql =
        "select e.n_end, e.descricao_end, e.endcompleto_end, e.bairro_end, e.complemento_end, e.numero_end, " +
        "e.cidade_end||' / '||e.uf_end cidade_end " +
        "from enderecos e " +
        "where e.n_pes = " +
        this.tpai.N_PES_CLIENTE +
        " " +
        "order by e.n_end";
      sql = encodeURIComponent(sql);
      this.qGridEnderecos = [];
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.qGridEnderecos = response.data;
        this.isLoading = false;
      });
    },
    btnPesquisarProdutoClick() {
      this.edPesquisaProduto = "";
      this.qGridProdutos = [];
      this.bolMostraGridProduto = false;
      this.bolDialogPesqProduto = true;
    },
    btnConsultarClienteClick() {
      if (this.edPesquisaCliente.length >= 3) {
        this.isLoading = true;
        let sql =
          "select p.n_pes, p.razao_pes, p.nome_pes, p.cep_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
          "p.bairro_pes, p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.email_pes, p.cidade_pes, p.cidade_pes||' / '||p.uf_pes cidadeuf_pes, p.uf_pes, " +
          "p.codibge_municipio, p.complemento_pes, p.contato_pes " +
          "from adm_pessoa p " +
          "where p.tipopes_pes = 'CLIENTE'" +
          "and p.status_pes = 'ATIVO'" +
          "and ((p.razao_pes like '%" +
          this.edPesquisaCliente +
          "%') or (p.nome_pes like '%" +
          this.edPesquisaCliente +
          "%')) " +
          "order by 2";
        sql = encodeURIComponent(sql);
        this.qGridClientes = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.qGridClientes = response.data;
          this.bolMostraGridCliente = true;
          this.isLoading = false;
        });
      }
    },
    btnSelecionarProdutoClick(item) {
      this.bolDialogPesqProduto = false;
      this.tfilho.N_PRO_MARCA = item.N_PRO_MARCA;
      this.tfilho.VALORUNITARIO_ITEM = item.VALOR_VENDA;
      this.tfilho.UN_PRO = item.UN_PRO;
      this.strNomeProdutoSelecionado = item.NOME_PRO;
    },
    btnSelecionarEnderecoClick(item) {
      this.bolDialogPesqEndereco = false;
      this.tpai.N_END = item.N_END;
      this.tpai.END_ENT = item.ENDCOMPLETO_END;
    },
    btnSelecionarClienteClick(item) {
      this.bolDialogPesqCliente = false;
      this.tpai.N_PES_CLIENTE = item.N_PES;
      this.tpai.RAZAO_CLI = item.RAZAO_PES;
    },
    btnConsultaViaCEPClick() {
      if (this.tpai.CEP_CLI != "") {
        //Nova variável "cep" somente com dígitos.
        var cep = this.tpai.CEP_CLI.replace(/\D/g, "");
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          this.isLoadingCEP = true;
          viacep.get("/" + cep + "/json/").then((response) => {
            if (response.data.erro === true) {
              this.isLoadingCEP = false;
              this.snackbartext = "CEP inválido";
              this.snackbar = true;
            } else {
              let strLogradouro = response.data.logradouro.toUpperCase();
              let strTipoLogradouro = strLogradouro.slice(
                0,
                strLogradouro.indexOf(" ")
              );
              let strEndLogradouro = strLogradouro.slice(
                strLogradouro.indexOf(" ") + 1
              );
              this.tpai.TIPOEND_CLI = strTipoLogradouro;
              this.tpai.END_CLI = strEndLogradouro;
              this.tpai.BAIRRO_CLI = response.data.bairro.toUpperCase();
              this.tpai.CIDADE_CLI = response.data.localidade.toUpperCase();
              this.tpai.UF_CLI = response.data.uf.toUpperCase();
              //this.tpai.CODIBGE_MUNICIPIO = response.data.ibge;
              this.isLoadingCEP = false;
            }
          });
        } else {
          this.isLoadingCEP = false;
          this.snackbartext = "CEP inválido";
          this.snackbar = true;
        }
      }
    },
    formataDataISO(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    getDataHoje: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.dataHoje = today;
    },
    localizaregtpai(aFuncao) {
      if (aFuncao === "INSERT") {
        this.isLoading = true;
        let sql = "select p.* from pedido p where p.n_ped = -1";
        sql = encodeURIComponent(sql);
        this.tpai = [];
        api.get("/v1/consultaobjeto?sql=" + sql).then((response) => {
          this.tpai = response.data;
          this.tpai.N_PED = 0; //indica insert
          this.tpai.N_EMP = this.$store.state.vempresa;
          this.tpai.N_USU = this.$store.state.codusuario;
          this.tpai.N_PES_VENDEDOR = this.$store.state.codfuncionario;
          this.tpai.STATUS_PED = "EM ABERTO";
          this.isLoading = false;
        });
      } else {
        //CANCEL apenas limpa o tpai
        this.tpai = {};
      }
    },
    localizaregtfilho() {
      this.isLoading = true;
      let sql = "select p.* from pedidoitens p where p.n_item = -1";
      sql = encodeURIComponent(sql);
      this.tfilho = [];
      api.get("/v1/consultaobjeto?sql=" + sql).then((response) => {
        this.tfilho = response.data;
        this.isLoading = false;
      });
    },
    btnIncluirClick() {
      if (this.$store.state.codfuncionario == "") {
        this.snackbartext = "Vendedor não configurado";
        this.snackbar = true;
      } else {
        this.localizaregtpai("INSERT");
        this.bolNovoEndereco = false;
        this.strStatusRegistro = "INSERT";
        this.qGridFilho = [];
      }
    },
    btnAlterarClick(item) {
      if (item.STATUS_PED === "EM ABERTO") {
        let sql = "select p.* from pedido p where p.n_ped = " + item.N_PED;
        sql = encodeURIComponent(sql);
        api.get("/v1/consultaobjeto?sql=" + sql).then((response) => {
          this.tpai = response.data;
          this.tpai.DATA_CAD = this.formataDataISO(this.tpai.DATA_CAD);
          this.tpai.ENTREGARDATA_PED = this.formataDataISO(
            this.tpai.ENTREGARDATA_PED
          );
          this.strStatusRegistro = "EDIT";
          this.qGridFilho = [];
          this.bolNovoEndereco = false;
          if (this.tpai.END_ENT == "" || this.tpai.END_ENT == null) {
            this.bolNovoEndereco = false;
          } else {
            this.tpai.END_ENT =
              this.tpai.TIPOEND_ENT +
              " " +
              this.tpai.END_ENT +
              ", " +
              this.tpai.NUMERO_ENT;
          }
          this.btnConsultarItensClick();
          window.scrollTo(0, 0);
        });
      } else {
        this.snackbartext = "Pedido não está mais EM ABERTO";
        this.snackbar = true;
      }
    },
    btnSalvarClick() {
      if (this.$refs.form.validate()) {
        if (this.qGridFilho.length === 0) {
          this.snackbartext = "Nenhum produto inserido";
          this.snackbar = true;
        } else {
          this.tpai.ITENS = this.qGridFilho;
          api
            .post(
              "/v1/pedidosexternos",
              JSON.stringify(this.tpai, this.replacer).toUpperCase()
            )
            .then((response) => {
              if (response.data.RESULTADO === "OK") {
                window.scrollTo(0, 0);
                this.bolMostraGrid = true;
                this.strStatusRegistro = "";
                this.btnPesquisarClick();
              } else {
                this.snackbartext = response.data;
                this.snackbar = true;
              }
            });
        }
      }
    },
    btnCancelarClick() {
      this.localizaregtpai("CANCEL");
      this.strStatusRegistro = "";
      window.scrollTo(0, 0);
    },
    btnExcluirClick() {
      api.delete("/v1/pedidosexternos/" + this.tpai.N_PED).then((response) => {
        if (response.data.RESULTADO == "OK") {
          window.scrollTo(0, 0);
          this.bolMostraGrid = true;
          this.strStatusRegistro = "";
          this.btnPesquisarClick();
        } else {
          this.snackbartext = response.data.ERRO;
          this.snackbar = true;
        }
      });
    },

    btnPesquisarClick() {
      if (this.$store.state.codfuncionario == "") {
        this.snackbartext = "Vendedor não configurado";
        this.snackbar = true;
      } else {
        this.isLoading = true;
        let sql =
          "select p.n_ped, " +
          "lpad(extract(day from p.data_cad),2) || '/'  || lpad(extract(month from p.data_cad),2) || '/' || lpad(extract(year from p.data_cad),4)" +
          "||' '||p.hora_cad data_cad, cast(p.valor_ped as float) valor_ped, a.razao_pes, " +
          "case " +
          "when p.n_end is null then (a.endcompleto_pes) " +
          "else (select e.endcompleto_end from enderecos e where e.n_end = p.n_end) " +
          "end as endcompleto_pes, " +
          "a.cidade_pes||' / '||a.uf_pes cidade_pes, a.uf_pes, p.status_ped, " +
          "(select x.razao_pes from adm_pessoa x where x.n_pes = p.n_pes_vendedor) nome_vendedor " +
          "from pedido p, adm_pessoa a " +
          "where p.n_pes_cliente = a.n_pes " +
          "and p.status_ped = 'EM ABERTO' " +
          "and p.n_emp = " +
          this.$store.state.vempresa +
          " " +
          "and ((a.razao_pes like '%" +
          this.edPesquisa +
          "%') " +
          " or (p.n_ped like '%" +
          this.edPesquisa +
          "%')) ";

        if (this.bolFiltraVendedor == true) {
          sql =
            sql +
            "and p.n_pes_vendedor = " +
            this.$store.state.codfuncionario +
            " ";
        }

        sql = sql + "order by p.n_ped desc";

        sql = encodeURIComponent(sql);
        this.qGrid = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.qGrid = response.data;
          this.bolMostraGrid = true;
          this.isLoading = false;
        });
      }
    },
    carregaTipoCobranca() {
      let sql =
        "select t.n_tip, t.descricao " +
        "from tipo_cobranca t " +
        "where t.tipo = 'C' " +
        "and t.pdv_tip = 'S' " +
        "order by 2";
      sql = encodeURIComponent(sql);
      this.qTipoCobranca = [];
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.qTipoCobranca = response.data;
      });
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
  },
};
</script>