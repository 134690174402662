<template>
  <v-container fluid class="d-flex flex-column">
    <div class="pb-2 text-center text-h6">
      <v-card
        v-if="bolMotraPesquisa"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-text-field
            v-model="edpesquisa"
            hide-details
            single-line
            label="Número da O.S."
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" :loading="isloading" @click="consultarapi()">
            Iniciar
          </v-btn>
        </v-col>
      </v-card>
    </div>

    <div class="pb-2 text-h6">
      <!-- card com dados da OS -->
      <v-card v-if="bolDadosOS" max-width="500px" class="blue-grey lighten-5">
        <v-card-title
          >O.S.
          {{ this.TPAI.N_ORD }}
        </v-card-title>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle
              >Data: {{ this.TPAI.DATA_OS }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              >Contrato: {{ this.TPAI.NUMERO_CONTRATO }}</v-list-item-subtitle
            >
            <v-list-item-subtitle>{{
              this.TPAI.RAZAO_PES
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>

    <div class="pb-2 text-center text-h6">
      <!-- card de saida da usina -->
      <v-card
        v-if="bolSaidaUsina"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-select
            v-model="TPAI.CLIMA_OS"
            label="Clima"
            :items="['ENSOLARADO', 'NUBLADO', 'CHUVOSO']"
          ></v-select>
          <v-text-field
            v-model="TPAI.ADIAGUAFILIAL_OS"
            label="Adição de água na usina (litros)"
          ></v-text-field>
          <v-text-field
            v-model="TPAI.SLUMPSAIDAFILIAL_OS"
            label="Slump saída da usina"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmarsaidausina()"
          >
            Confirmar saída da usina
          </v-btn>
        </v-col>
      </v-card>
      <!-- card de chegada na obra -->
      <v-card
        v-if="bolChegadaObra"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmarchegadaobra()"
          >
            Confirmar chegada na obra
          </v-btn>
        </v-col>
      </v-card>
      <!-- card de inicio do descarregamento -->
      <v-card
        v-if="bolInicioDescarregamento"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmariniciodescarregamento()"
          >
            Início descarregamento
          </v-btn>
        </v-col>
      </v-card>
      <!-- card de fim do descarregamento -->
      <v-card
        v-if="bolFimDescarregamento"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-text-field
            v-model="TPAI.ADIAGUAOBRA_OS"
            label="Adição de água na obra (litros)"
          ></v-text-field>
          <v-text-field
            v-model="TPAI.SLUMPLANCAMENTO_OS"
            label="Slump de descarregamento"
          ></v-text-field>
          <v-text-field
            v-model="TPAI.OCORRENCIA_OS"
            label="Ocorrência"
          ></v-text-field>
          <v-text-field
            v-model="TPAI.RECEBEDOR_OS"
            label="Recebedor"
          ></v-text-field>
          <v-text-field
            v-model="TPAI.DOCRECEBEDOR_OS"
            label="Documento recebedor"
          ></v-text-field>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmarfimdescarregamento()"
          >
            Fim descarregamento
          </v-btn>
        </v-col>
      </v-card>
      <!-- card de saída na obra -->
      <v-card v-if="bolSaidaObra" max-width="500px" class="blue-grey lighten-5">
        <v-col>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmarsaidaobra()"
          >
            Confirmar saída da obra
          </v-btn>
        </v-col>
      </v-card>
      <!-- card de chegada na usina -->
      <v-card
        v-if="bolChegadaUsina"
        max-width="500px"
        class="blue-grey lighten-5"
      >
        <v-col>
          <v-btn
            color="primary"
            :loading="isloading"
            @click="confirmarchegadausina()"
          >
            Confirmar chegada na usina
          </v-btn>
        </v-col>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "OsComplemento",
  data() {
    return {
      bolMotraPesquisa: true,
      bolDadosOS: false,
      bolSaidaUsina: false,
      bolChegadaObra: false,
      bolInicioDescarregamento: false,
      bolFimDescarregamento: false,
      bolSaidaObra: false,
      bolChegadaUsina: false,
      isloading: false,
      TPAI: {},
      edpesquisa: "",
      sqlConsulta: "",
      snackbar: false,
      snackbartext: "",
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "ENTREGA";
  },
  methods: {
    atualizaTpai() {
      if (this.edpesquisa != "") {
        this.isloading = true;
        this.sqlConsulta =
          "select o.n_ord, o.clima_os, o.ocorrencia_os, o.recebedor_os, o.docrecebedor_os, " +
          "o.adiaguaobra_os, o.slumplancamento_os, o.slumpsaidafilial_os, o.adiaguafilial_os, p.slump, " +
          "o.data_os, c.numero_contrato, a.razao_pes, " +
          "substring(o.horasaidafilial_os from 1 for 8) horasaidafilial_os, " +
          "substring(o.horachegobra_os from 1 for 8) horachegobra_os, " +
          "substring(o.horainilancamento_os from 1 for 8) horainilancamento_os, " +
          "substring(o.horafimlancamento_os from 1 for 8) horafimlancamento_os, " +
          "substring(o.horasaidaobra_os from 1 for 8) horasaidaobra_os, " +
          "substring(o.horachegfilial_os from 1 for 8) horachegfilial_os " +
          "from ordem_servico o, produtos p, contratos c, adm_pessoa a " +
          "where o.n_pro = p.n_pro " +
          "and o.n_con = c.n_con " +
          "and c.n_pes_cliente = a.n_pes " +
          "and o.n_ord = " +
          this.edpesquisa;
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.TPAI = {};
        api
          .get("/v1/consulta?sql=" + this.sqlConsulta)
          .then((response) => {
            this.TPAI = response.data[0];
            this.isloading = false;
          })
          .catch((error) => {
            this.isloading = false;
            this.snackbartext = error.response.data;
            this.snackbar = true;
          });
      }
    },
    consultarapi() {
      if (this.edpesquisa != "") {
        this.isloading = true;
        this.sqlConsulta =
          "select o.n_ord, o.clima_os, o.ocorrencia_os, o.recebedor_os, o.docrecebedor_os, " +
          "o.adiaguaobra_os, o.slumplancamento_os, o.slumpsaidafilial_os, o.adiaguafilial_os, p.slump, " +
          "o.data_os, c.numero_contrato, a.razao_pes, " +
          "substring(o.horasaidafilial_os from 1 for 8) horasaidafilial_os, " +
          "substring(o.horachegobra_os from 1 for 8) horachegobra_os, " +
          "substring(o.horainilancamento_os from 1 for 8) horainilancamento_os, " +
          "substring(o.horafimlancamento_os from 1 for 8) horafimlancamento_os, " +
          "substring(o.horasaidaobra_os from 1 for 8) horasaidaobra_os, " +
          "substring(o.horachegfilial_os from 1 for 8) horachegfilial_os " +
          "from ordem_servico o, produtos p, contratos c, adm_pessoa a " +
          "where o.n_pro = p.n_pro " +
          "and o.n_con = c.n_con " +
          "and c.n_pes_cliente = a.n_pes " +
          "and o.n_ord = " +
          this.edpesquisa;
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.TPAI = {};
        api
          .get("/v1/consulta?sql=" + this.sqlConsulta)
          .then((response) => {
            this.TPAI = response.data[0];
            this.isloading = false;

            this.bolMotraPesquisa = false;
            this.bolDadosOS = true;

            if (this.TPAI.HORASAIDAFILIAL_OS === null) {
              if (this.TPAI.ADIAGUAFILIAL_OS === null) {
                this.TPAI.ADIAGUAFILIAL_OS = "0";
              }
              if (this.TPAI.SLUMPSAIDAFILIAL_OS === null) {
                this.TPAI.SLUMPSAIDAFILIAL_OS = this.TPAI.SLUMP;
              }
              this.bolSaidaUsina = true;
            } else if (this.TPAI.HORACHEGOBRA_OS === null) {
              this.bolChegadaObra = true;
            } else if (this.TPAI.HORAINILANCAMENTO_OS === null) {
              this.bolInicioDescarregamento = true;
            } else if (this.TPAI.HORAFIMLANCAMENTO_OS === null) {
              if (this.TPAI.ADIAGUAOBRA_OS === null) {
                this.TPAI.ADIAGUAOBRA_OS = "0";
              }
              if (this.TPAI.SLUMPLANCAMENTO_OS === null) {
                this.TPAI.SLUMPLANCAMENTO_OS = this.TPAI.SLUMP;
              }
              this.bolFimDescarregamento = true;
            } else if (this.TPAI.HORASAIDAOBRA_OS === null) {
              this.bolSaidaObra = true;
            } else if (this.TPAI.HORACHEGFILIAL_OS === null) {
              this.bolChegadaUsina = true;
            } else {
              this.bolMotraPesquisa = true;
              this.bolDadosOS = false;
              this.edpesquisa = "";
              this.snackbartext = "Esta O.S. já foi entregue";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.isloading = false;
            this.snackbartext = error.response.data;
            this.snackbar = true;
          });
      }
    },
    confirmarsaidausina() {
      if (this.TPAI.CLIMA_OS === null) {
        this.snackbartext = "Clima é obrigatório";
        this.snackbar = true;
      } else if (this.TPAI.ADIAGUAFILIAL_OS === null) {
        this.snackbartext = "Informe se houve adição de água";
        this.snackbar = true;
      } else if (this.TPAI.SLUMPSAIDAFILIAL_OS === null) {
        this.snackbartext = "Informe o Slump de saída";
        this.snackbar = true;
      } else {
        this.isloading = true;
        api
          .post("/v1/entregas/saidausina", JSON.stringify(this.TPAI))
          .then((response) => {
            this.isloading = false;
            if (response.data.RESULTADO === "OK") {
              this.bolSaidaUsina = false;
              this.bolChegadaObra = true;
              this.atualizaTpai();
            } else {
              this.snackbartext = response.data;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.isloading = false;
            this.snackbartext = error.response.data;
            this.snackbar = true;
          });
      }
    },
    confirmarchegadaobra() {
      this.isloading = true;
      api
        .post("/v1/entregas/chegadaobra", JSON.stringify(this.TPAI))
        .then((response) => {
          this.isloading = false;
          if (response.data.RESULTADO === "OK") {
            this.bolChegadaObra = false;
            this.bolInicioDescarregamento = true;
            this.atualizaTpai();
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.isloading = false;
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
    confirmariniciodescarregamento() {
      this.isloading = true;
      api
        .post("/v1/entregas/iniciodescarregamento", JSON.stringify(this.TPAI))
        .then((response) => {
          this.isloading = false;
          if (response.data.RESULTADO === "OK") {
            this.bolInicioDescarregamento = false;
            this.bolFimDescarregamento = true;
            if (this.TPAI.ADIAGUAOBRA_OS === null) {
              this.TPAI.ADIAGUAOBRA_OS = "0";
            }
            if (this.TPAI.SLUMPLANCAMENTO_OS === null) {
              this.TPAI.SLUMPLANCAMENTO_OS = this.TPAI.SLUMP;
            }
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.isloading = false;
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
    confirmarfimdescarregamento() {
      if (this.TPAI.ADIAGUAOBRA_OS === null) {
        this.snackbartext = "Informe se houve adição de água";
        this.snackbar = true;
      } else if (this.TPAI.SLUMPLANCAMENTO_OS === null) {
        this.snackbartext = "Informe o slump de descarregamento";
        this.snackbar = true;
      } else if (this.TPAI.RECEBEDOR_OS === null) {
        this.snackbartext = "Informe o nome do recebedor";
        this.snackbar = true;
      } else if (this.TPAI.DOCRECEBEDOR_OS === null) {
        this.snackbartext = "Informe o documento do recebedor";
        this.snackbar = true;
      } else {
        this.isloading = true;
        api
          .post("/v1/entregas/fimdescarregamento", JSON.stringify(this.TPAI))
          .then((response) => {
            this.isloading = false;
            if (response.data.RESULTADO === "OK") {
              this.bolFimDescarregamento = false;
              this.bolSaidaObra = true;
            } else {
              this.snackbartext = response.data;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.isloading = false;
            this.snackbartext = error.response.data;
            this.snackbar = true;
          });
      }
    },
    confirmarsaidaobra() {
      this.isloading = true;
      api
        .post("/v1/entregas/saidaobra", JSON.stringify(this.TPAI))
        .then((response) => {
          this.isloading = false;
          if (response.data.RESULTADO === "OK") {
            this.bolSaidaObra = false;
            this.bolChegadaUsina = true;
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.isloading = false;
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
    confirmarchegadausina() {
      this.isloading = true;
      api
        .post("/v1/entregas/chegadausina", JSON.stringify(this.TPAI))
        .then((response) => {
          this.isloading = false;
          if (response.data.RESULTADO === "OK") {
            this.bolChegadaUsina = false;
            this.bolDadosOS = false;
            this.bolMotraPesquisa = true;
            this.edpesquisa = "";
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.isloading = false;
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
  },
};
</script>